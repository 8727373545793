import $http from '@/utils/http';

/**
 * 登录
 * @param data
 * @returns {Promise<unknown>}
 */
export function login(data) {
  return $http.post('/api/Login/index', data, 'send_alisms');
}

/**
 * 登录-新
 * @param data
 * @returns {Promise<unknown>}
 */
export function loginNew(data) {
  return $http.post('/api/login', data, 'send_alisms');
}

/**
 * 发送验证码
 * @param data
 * @returns {Promise<unknown>}
 */
export function send_smsNew(data) {
  return $http.post('/api/send_sms', data, 'false');
}

/**
 * 发送验证码
 * @param data
 * @returns {Promise<unknown>}
 */
export function getYzm(data) {
  return $http.post('/api/Sms/send', data);
}

/**
 * 校验验证码
 * @param data
 * @returns {Promise<unknown>}
 */
export function validateYzm(data) {
  return $http.post('/api/Login/ck_sms', data);
}

/**
 * 更改密码
 * @param data
 * @returns {Promise<unknown>}
 */
export function changePassWord(data) {
  return $http.post('/api/Login/newpassword', data);
}
